import kamalaImage from "../assets/kamala.jpg";
import banner1Image from "../assets/banner-1.jpg";
import banner2Image from "../assets/banner-2.jpg";
import carImage from "../assets/car.png";
import burnImage from "../assets/burn.png";
import roadmapImage from "../assets/roadmap.png";
import aboutImage from "../assets/about.png";
import tokenomicImage from "../assets/tokenomic.png";
import dextoolImage from "../assets/dextool.png";
import candleImage from "../assets/candle.gif";
import bidenandharris from "../assets/bidenandharris.mp4";
import bananaImage from "../assets/banana.png";
import baseImage from "../assets/base.svg";

import { useEffect, useState } from "react";

const roadmapItems = [
  {
    phase: "1",
    title: "Campaign preparation",
    description:
      "Start recruiting a strong, committed community / 10k followers",
  },
  {
    phase: "2",
    title: "Execution of executive order",
    description:
      "Equal pre-sale. Launch of $KAKA token on channel chosen by community vote (not rigged)",
  },
  {
    phase: "3",
    title: "Senate farming / airdrop of empty promises",
    description:
      "Place your tokens in our 'Senate Floor' pool. The more you talk on X and Telegram, the more you win!",
  },
  {
    phase: "4",
    title: "Election campaign",
    description:
      "KAKAMALA meme president. Shill is highly recommended, the most creative wins a 'Cabinet Position' promotion!",
  },
  {
    phase: "5",
    title: "Inauguration of deflation",
    description: "Massive token-burning event.",
  },
  {
    phase: "6",
    title: "Exchange of Supreme Court judges",
    description:
      "Launch of FairPump DEX with anti-snipe launch for a secure Degen environment where only degeneracy is involved.",
  },
  {
    phase: "7",
    title: "To the Moon... and beyond!",
    description:
      "$KAKA becomes the official currency of our new Martian colony. Earth laws don't apply - the journey has just begun!",
  },
];

const RoadmapItem = ({
  phase,
  title,
  description,
}: {
  phase: string;
  title: string;
  description: string;
}) => (
  <li className="group mb-6 last:mb-0">
    <div className="flex items-center space-x-3 mb-2">
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#164891] text-white flex items-center justify-center font-bold transition-all duration-300 group-hover:scale-110 group-hover:rotate-[360deg]">
        {phase}
      </div>
      <h3
        className="text-3xl font-semibold group-hover:text-[#164891] transition-colors duration-300"
        id="text-stroke-sm"
      >
        {title}
      </h3>
    </div>
    <div className="ml-13 pl-8 transition-colors duration-300">
      <p className="text-start text-gray-200 group-hover:text-white transition-colors duration-300 text-xl">
        {description}
      </p>
    </div>
  </li>
);

export function LandingPage() {
  const launchDate = new Date("2024-10-13T01:00:00.000Z").getTime();

  function calculateTimeLeft(): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const difference = launchDate - new Date().valueOf();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const { days, hours, minutes, seconds } = timeLeft;

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 100);

    return () => clearTimeout(timer);
  });

  function formatTime(time: number) {
    return time < 10 ? `0${time}` : time;
  }

  const daysText = days;
  const hoursText = formatTime(hours);
  const minutesText = formatTime(minutes);
  const secondsText = formatTime(seconds);

  return (
    <div className="flex flex-col relative w-full">
      <div className="flex items-center justify-between px-10 h-[97px] border-b-4 border-black shadow-xl fixed w-full bg-gradient-to-r from-[#688ec7] via-white to-[#c78c8d] z-50">
        <div className="flex items-center gap-2 cursor-pointer">
          <img
            src={kamalaImage}
            alt="Kamala logo"
            className="rounded h-16"
          />
          <span className="text-2xl">KAKAMALA FOR KRYPTO</span>
        </div>
        <div className="items-center gap-10 text-2xl cursor-pointer hidden lg:flex">
          <a href="#about">
            <span className="transition-all duration-500 hover:text-white">
              About
            </span>
          </a>
          <a href="#tokenomic">
            <span className="transition-all duration-500 hover:text-white">
              Tokenomics
            </span>
          </a>

          <a
            href="https://ape.store/base/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
            target="_blank"
            rel="noreferrer"
          >
            <span className="transition-all duration-500 hover:text-white">
              BUY $KAKA
            </span>
          </a>
        </div>
        <button
          className="lg:hidden text-2xl"
          id="headlessui-menu-button-:r0:"
          type="button"
          aria-haspopup="menu"
          aria-expanded="false"
          data-headlessui-state=""
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            className="svg-inline--fa fa-bars"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col items-center pt-[97px] justify-center w-full">
          <video autoPlay muted loop className="right-0 top-0 min-w-full">
            <source src={bidenandharris} type="video/mp4" />
          </video>

          <div className="bg-cover w-full h-full bg-center flex items-center flex-col relative">
            <div className="flex items-center gap-4 flex-row w-full justify-center absolute -bottom-7 px-2 lg:px-0">
              <span
                className="text-3xl md:text-4xl lg:text-5xl max-w-[800px] text-center font-bold tracking-tighter absolute bottom-32"
                id="text-stroke-sm"
              >
                The only token guaranteed to outperform political promises!{" "}
              </span>

              <a
                href="https://ape.store/base/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
                target="_blank"
                rel="noreferrer"
                className="relative inline-block"
              >
                <button className="animate-ping-slow pointer-events-none absolute inset-0 z-10 px-4 py-3 md:text-xl text-black rounded-lg text-sm md:w-[300px] bg-[#3CFF73] duration-500 transition-all border-b-[6px] shadow-xl">
                  BUY $KAKA
                </button>
                <button className="relative z-0 px-4 py-3 md:text-xl text-black hover:bg-white rounded-lg text-sm md:w-[300px] bg-[#3CFF73] duration-500 transition-all border-b-[6px] hover:border-b-[#808080] border-b-[#106A29] shadow-xl">
                  BUY $KAKA
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="flex bg-white w-full justify-center items-center px-4 shadow-[0px_0px_34px_0px_rgba(0,0,0,0.25)]"
          id="about"
        >
          <div className="flex flex-col items-center h-full text-center max-w-[830px] justify-center -mx-4 w-full py-24">
            <img
              src={aboutImage}
              className="animate-[wiggle_2s_ease-in-out_infinite] self-center h-[100px] my-12"
              alt="about kakamala"
            />
            <span
              className="text-4xl sm:text-7xl font-bold tracking-tighter mb-4"
              id="text-stroke-sm"
            >
              Imagine this...
            </span>

            <p className="flex flex-col gap-4 h-full leading-relaxed">
              <span className="text-base sm:text-xl">
                Meet Kakamala ($KAKA), a creation born from the crypto
                community's frustration with comrade Harris flip-flopping
                stance on digital assets, who's been as consistent as a
                chameleon in a disco Kakamala stands firm in its commitment
                to financial freedom. Why such unwavering dedication?
                Because Kakamala represents everything Harris isn't:
                transparent, consistent, and utterly immune to the
                flip-flops that seem to plague certain vice presidents we
                know.
              </span>

              <span className="text-base sm:text-xl">
                For the sheer joy of exposing political hypocrisy, Kakamala
                has engineered a mechanism that outpaces Harris's
                backpedaling on crypto issues.. Every time Comrade Harris
                makes a KAKAMALA - in other words, contradictory statement
                or blunder - the community will be paid to relay that we
                have a KAKAMALA leaving her dizzying policy shifts in the
                dust. The result: every contradictory statement by the
                Vice-President triggers an increase in support for
                KAKAMALA, and her $KAKA, which boosts her influence. With a
                sharply reducing patience for empty promises , $KAKA is
                ready to soar, showcasing the most brilliantly satirical
                tokenomics ever devised in response to political
                crypto-confusion.
              </span>

              <img
                src={carImage}
                className="animate-[bounce_1.5s_ease-in-out_infinite] self-center w-[300px] md:w-[450px] lg:w-[600px] py-6 mt-12 md:mt-16 lg:mt-18"
                alt="kamala car"
              />

              <span className="text-base sm:text-xl">
                This is the pinnacle of Expose Political Hypocrisy' (EPH)
                technology—a privilege for those who see through the
                smokescreen! Join at your own risk and witness the rise of
                Kakamala, the supreme crypto-satire movement, the ultimate
                meme exposing political inconsistency!
              </span>

              <span className="text-base sm:text-xl">
                To all those buying into Harris's newfound crypto
                enthusiasm: wake up and smell the opportunism.
                <br />
                <span className="text-lg font-semibold sm:text-3xl">
                  Only the truly visionary dare to hodl the unhodlable!
                </span>
              </span>

              <span className="text-base sm:text-xl">
                <span className="text-lg font-semibold sm:text-3xl">
                  The verbal diarrhea is over !
                </span>
              </span>
            </p>

            <div className="text-sm sm:text-lg text-center mt-10 tracking-widest">
              <span>CONTRACT ADDRESS</span>
              <div className="flex items-center space-x-2">
                <a
                  href="https://basescan.org/token/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
                  target="_blank"
                  className="underline underline-offset-2"
                >
                  0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tokenomic"
          className="bg-[#78a4e7] flex gap-12 px-4 justify-between items-center flex-col py-24 w-full"
        >
          <img
            src={tokenomicImage}
            className="animate-[wiggle_2s_ease-in-out_infinite] h-[100px] my-12"
            alt="tokenomic kakamala"
          />

          <div className="flex flex-col gap-12 justify-center">
            <div className="flex flex-col items-center">
              <span
                className="text-3xl sm:text-4xl mb-2"
                id="text-stroke-sm"
              >
                $KAKA ADDRESS
              </span>
              <a
                href="https://basescan.org/token/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
                target="_blank"
                rel="noreferrer"
                className="rounded-lg w-full"
              >
                <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                  <div className="py-4 text-2xl sm:text-3xl text-white bg-[#c52527] rounded-lg w-full duration-500 transition-all border-2 border-black text-center shadow-2xl mb-[-6px] flex items-center justify-center">
                    0xc29de11dd8...f1d652c1e9fa
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <span
                className="text-3xl sm:text-4xl mb-2"
                id="text-stroke-sm"
              >
                $KAKA SUPPLY
              </span>
              <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                <div className="py-4 text-3xl sm:text-4xl text-white bg-[#c52527] rounded-lg w-full duration-500 transition-all border-2 border-black text-center shadow-2xl mb-[-6px] flex items-center justify-center">
                  1,000,000,000
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center p-4">
              <div
                className="text-6xl font-semibold mb-6"
                id="text-stroke-sm"
              >
                Simple and Fair Launch
              </div>
              <div className="text-3xl font-bold text-[#c52527] mb-4 flex items-center">
                on
                <img
                  src={bananaImage}
                  className="mx-2 h-8 w-8 animate-spin"
                />
                <a
                  href="https://ape.store/base/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
                  target="_blank"
                  rel="noreferrer"
                >
                  ape.store
                </a>
              </div>
              <div className="text-xl font-medium text-[#164891] flex items-center">
                ( Base network
                <img
                  src={baseImage}
                  className="mx-2 h-8 w-8 animate-spin"
                />{" "}
                )
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div
                className="text-3xl sm:text-4xl mb-2"
                id="text-stroke-sm"
              >
                LAUNCH IN
              </div>

              <div className="flex items-center gap-2 w-full">
                <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                  <div className="py-4 sm:w-40 flex items-center justify-center text-3xl sm:text-4xl text-white bg-[#c52527] rounded-lg duration-500 transition-all text-center shadow-2xl border-black border-2 mb-[-7px]">
                    {daysText} <span className="text-black"> d</span>
                  </div>
                </div>
                <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                  <div className="py-4 sm:w-40 flex items-center justify-center text-3xl sm:text-4xl text-white bg-[#c52527] rounded-lg duration-500 transition-all text-center shadow-2xl border-black border-2 mb-[-7px]">
                    {hoursText} <span className="text-black"> h</span>
                  </div>
                </div>
                <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                  <div className="py-4 sm:w-40 flex items-center justify-center text-3xl sm:text-4xl text-white bg-[#c52527] rounded-lg duration-500 transition-all text-center shadow-2xl border-black border-2 mb-[-7px]">
                    {minutesText} <span className="text-black">m</span>
                  </div>
                </div>
                <div className="border-b-[12px] border-b-[#531819] rounded-lg w-full">
                  <div className="py-4 sm:w-40 flex items-center justify-center text-3xl sm:text-4xl text-white bg-[#c52527] rounded-lg duration-500 transition-all text-center shadow-2xl border-black border-2 mb-[-7px]">
                    {secondsText} <span className="text-black">s</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={candleImage}
            className="self-center w-[400px] md:w-[550px] lg:w-[700px] py-6"
            alt="kamala candle"
          />

          <div className="text-3xl sm:text-4xl mb-2" id="text-stroke-sm">
            PRICE
          </div>

          {/*    <div className="w-full max-w-[800px] h-[500px] rounded-lg shadow-2xl -mt-12 bg-gradient-to-br from-blue-100 to-blue-200 flex flex-col items-center justify-center overflow-hidden relative">
            <div className="text-4xl font-bold text-blue-600 mb-4 animate-pulse">
              Coming Soon...
            </div>
            <div className="text-xl text-blue-500 mb-8">
              DEXTools Trading Chart
            </div>
            <div className="flex space-x-2">
              {[...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"
                  style={{ animationDelay: `${i * 0.15}s` }}
                />
              ))}
            </div>
            <div className="absolute inset-0 bg-white opacity-20">
              <svg
                className="w-full h-full"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 100 L100 100 L100 0 L0 0 Z"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                  vectorEffect="non-scaling-stroke"
                />
              </svg>
            </div>
          </div>*/}

          <iframe
            id="dextools-widget"
            title="DEXTools Trading Chart"
            className="w-full max-w-[800px] h-[500px] rounded-lg shadow-2xl -mt-12"
            src="https://www.dextools.io/widget-chart/en/base/pe-light/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa?theme=light&chartType=2&chartResolution=30&drawingToolbars=false"
          ></iframe>
        </div>

        {/* <div className="h-full w-full bg-[#d78384] py-24">
          <div className="flex flex-col items-center justify-between w-full h-full px-4 container mx-auto mt-12 max-w-[800px] "> */}

        <div
          className="flex w-full justify-center items-center px-4 bg-[#d78384]"
          id="about"
        >
          <div className="flex flex-col items-center h-full text-center max-w-[830px] justify-center -mx-4 w-full py-24">
            <img
              src={roadmapImage}
              className="animate-[wiggle_2s_ease-in-out_infinite] self-center h-[100px] my-12"
              alt="roadmap kakamala"
            />
            <p className="flex flex-col gap-4 h-full leading-relaxed">
              <span className="text-base sm:text-xl text-white">
                Introducing the Kakamala Roadmap: A journey more consistent
                than Harris's crypto stance, and infinitely more
                entertaining. Unlike the VP's policy flip-flops, our path
                forward is clear:
              </span>
            </p>

            <ul className="space-y-6 mt-16">
              {roadmapItems.map((item, index) => (
                <RoadmapItem key={index} {...item} />
              ))}
            </ul>
            <p className="mt-16 italic text-black text-center">
              Disclaimer: This roadmap is as reliable as a politician's
              promise. DYOR and don't invest your lunch money!
            </p>
          </div>
        </div>
        <img
          src={banner1Image}
          className="w-full h-full rounded-lg"
          alt="Banner 1"
        />
        <div
          className="flex w-full justify-center items-center px-4 bg-[#f1f1f1]"
          id="about"
        >
          <div className="flex flex-col items-center h-full text-center max-w-[830px] justify-center -mx-4 w-full py-24">
            <img
              src={burnImage}
              className="animate-[wiggle_2s_ease-in-out_infinite] self-center h-[100px] my-12"
              alt="burn kakamala"
            />
            <p className="flex flex-col gap-4 h-full leading-relaxed">
              <span className="text-base sm:text-xl">
                Look, here's the deal with burning tokens. Sometimes we'll
                burn some. How much? Who knows. When? Whenever we feel like
                it. Why? Because that's what these crypto folks seem to
                want. They say it'll pump the price or something. Does it
                work? Hell if I know. But hey, if it makes the hodlers
                happy and keeps them among us, we'll toss some tokens into
                the digital incinerator now and then.
              </span>
              <span className="text-xl sm:text-3xl">
                Minimum 15% of trading fees are allocated to buy back and
                burn ! If you want it Kakamala will do it !
              </span>
              <span className="text-base sm:text-xl">
                Just don't expect some grand announcement every time we do
                it. We're busy people, you know? Running a meme token is
                hard work. Or easy. Whatever. The point is, some tokens
                will disappear, the line might go up, and everyone can
                pretend they're financial geniuses. Happy now?
              </span>
              <span className="text-base sm:text-xl">
                Kakamala: Pioneering the audacious fusion of meme-driven
                fiscal policy and blockchain-enabled democratic
                satire—because in the grand tapestry of crypto-meme.
              </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-24 h-full justify-center px-4 items-center py-28 bg-[#164891] w-full">
          <span className="text-4xl sm:text-6xl text-center text-white max-w-[830px]">
            Kakamala: Pioneering the audacious fusion of meme-driven fiscal
            policy and blockchain-enabled democratic satire—because in the
            grand tapestry of crypto-governance, only the truly visionary
            dare to hodl the unhodlable!
          </span>
          <div className="flex items-center relative mt-12">
            <a
              href="https://ape.store/base/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
              target="_blank"
              rel="noreferrer"
              className="relative inline-block"
            >
              <button className="animate-ping-slow pointer-events-none absolute inset-0 z-10 px-4 py-3 md:text-xl text-black rounded-lg text-sm md:w-[300px] bg-[#3CFF73] duration-500 transition-all border-b-[6px] shadow-xl">
                BUY $KAKA
              </button>
              <button className="relative z-0 px-4 py-3 md:text-xl text-black hover:bg-white rounded-lg text-sm md:w-[300px] bg-[#3CFF73] duration-500 transition-all border-b-[6px] hover:border-b-[#808080] border-b-[#106A29] shadow-xl">
                BUY $KAKA
              </button>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <span
              className="text-5xl sm:text-8xl text-center mb-8"
              id="text-stroke-sm"
            >
              Follow Kakamala!
            </span>
            <div className="flex items-center justify-center gap-6">
              {/* <a
                href="https://www.dextools.io/app/en/base/pair-explorer/0xc29de11dd8cd0cd4baadcb04fdd9f1d652c1e9fa"
                target="_blank"
                rel="noreferrer"
              >
                <div className="animate-[bounce_1.4s_ease-in-out_infinite] delay-1000 flex items-center justify-center rounded-lg bg-red-500 p-4 cursor-pointer">
                  <img
                    src={dextoolImage}
                    className="color-black size-[60px]"
                    alt="Dexscreener"
                  />
                </div>
              </a> */}
              <a
                href="https://x.com/KakaMala2024"
                target="_blank"
                rel="noreferrer"
              >
                <div className="animate-[bounce_1s_ease-in-out_infinite] flex items-center justify-center rounded-lg bg-red-500 p-4 cursor-pointer">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="x-twitter"
                    className="svg-inline--fa fa-x-twitter size-[60px]"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="white"
                      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://t.me/Kakamala2024"
                target="_blank"
                rel="noreferrer"
              >
                <div className="animate-[bounce_1.7s_ease-in-out_infinite] flex items-center justify-center rounded-lg bg-red-500 p-4 cursor-pointer">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="telegram"
                    className="svg-inline--fa fa-github size-[60px]"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path
                      fill="white"
                      d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
